import { fabric } from 'fabric';
import $ from 'jquery';
import imageHelpers from '@/helpers/image';
import SelectionStyle from './components/SelectionStyle.vue';
import { log, parse } from 'handlebars';
import { personalizesApi } from '@/apis/personalizes';
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
// var FontFaceObserver = require('fontfaceobserver');

export default {
  components: { SelectionStyle },
  data() {
    let defaultSetting = {
      align: 'center',
      bold: false,
      fontColor: '#000000',
      fontSize: '25px',
      italic: false,
      underline: false,
      fontFamily: 'Arial',
      effect: 'STRAIGHT',
      maxLength: 1,
      text: 'A',
      caps: false,
    };
    return {
      isChangeOption: false,
      isAdd: true,
      fonts: [],
      e1: 1,
      imageHelpers,
      canvas: {
        product: null,
        preview: null,
      },
      optionsProductSetting: { ...defaultSetting },
      optionsPreviewSetting: { ...defaultSetting },
      defaultSetting,
      overlay: false,
      formData: {
        previewSvg: null,
        productionSvg: null,
        previewJson: null,
        productionJson: null,
      },
      imageUrl: {
        product: null,
        preview: null,
      },

      image: {
        src: null,
        size: {
          width: 0,
          height: 0,
        },
      },

      isLoading: false,
      isLoadingStyle: false,
      maxWidth: 0,
      listFontSize: [],
      previewSetting: {
        product: null,
        preview: null,
      },
      loadFCV: null,
    };
  },
  async created() {
    // await this.getFontPersonalize();

    for (let i = 11; i < 60; i++) {
      this.listFontSize.push({ name: `${i}px`, id: i });
    }
  },
  //   async mounted() {
  //     this.maxWidth = $('#content-canvas').width() - 30;
  //     this.canvas.product = new fabric.Canvas('cp');
  //     this.canvas.preview = new fabric.Canvas('cpr');
  //     this.loadFCV = new fabric.Canvas('c1');
  //     await this.getFontPersonalize();
  //     this.previewSetting.product = new fabric.Canvas('cpp');
  //     this.previewSetting.preview = new fabric.Canvas('cprp');
  //     let vm = this;
  //     //fire('mouse:dblclick'
  //     this.canvas.product.on('mouse:down', function(opt) {
  //       let target = opt.target;
  //       if (opt.target) {
  //         console.log('mouse', target);

  //         let t1 = opt.target._objects[1];
  //         vm.optionsProductSetting.text = t1.text;
  //         vm.optionsProductSetting.align = t1.textAlign;
  //         vm.optionsProductSetting.fontFamily = t1.fontFamily;
  //         vm.optionsProductSetting.bold = t1.fontWeight && t1.fontWeight === 'bold' ? true : false;
  //         vm.optionsProductSetting.fontStyle = t1.fontStyle && t1.fontStyle === 'italic' ? true : false;
  //         vm.optionsProductSetting.fontColor = t1.fill;
  //         vm.optionsProductSetting.effect = t1.effect;
  //         vm.optionsProductSetting.underline = t1.underline;
  //         vm.optionsProductSetting.fontSize = `${t1.fontSize}px`;
  //         vm.optionsProductSetting.caps = t1.styles === 'uppercase' ? true : false;

  //         // t1.width = t1.width * opt.target.scaleX;
  //         let sX = target.scaleX;
  //         let sY = target.scaleY;
  //         target.width *= sX;
  //         target.height *= sY;
  //         target.scaleX = 1;
  //         target.scaleY = 1;
  //         opt.target._objects[1].width = target.width;
  //         opt.target._objects[0].width = target.width;

  //         vm.$forceUpdate();
  //       }
  //     });
  //     this.canvas.product.on('object:scaling', function(opt) {
  //       var target = opt.target;
  //       if (!target || target.type !== 'group') {
  //         return;
  //       }
  //       var sX = target.scaleX;
  //       var sY = target.scaleY;
  //       target.width *= sX;
  //       target.height *= sY;
  //       target.scaleX = 1;
  //       target.scaleY = 1;
  //       opt.target._objects[1].width = target.width;
  //       opt.target._objects[0].width = target.width;
  //     });
  //     // this.canvas.product.on('after:render', function(e) {
  //     //   console.log('render', e);
  //     // });
  //     this.canvas.preview.on('mouse:down', function(opt) {
  //       try {
  //         let target = opt.target;
  //         if (opt.target) {
  //           console.log(opt.target);
  //           let t1 = opt.target._objects[1];
  //           vm.optionsPreviewSetting.text = t1.text;
  //           vm.optionsPreviewSetting.align = t1.textAlign;
  //           vm.optionsPreviewSetting.fontFamily = t1.fontFamily;
  //           vm.optionsPreviewSetting.bold = t1.fontWeight && t1.fontWeight === 'bold' ? true : false;
  //           vm.optionsPreviewSetting.fontStyle = t1.fontStyle && t1.fontStyle === 'italic' ? true : false;
  //           vm.optionsPreviewSetting.fontColor = t1.fill;
  //           vm.optionsPreviewSetting.effect = t1.effect;
  //           vm.optionsPreviewSetting.underline = t1.underline;
  //           vm.optionsPreviewSetting.fontSize = `${t1.fontSize}px`;
  //           vm.optionsPreviewSetting.caps = t1.styles === 'uppercase' ? true : false;

  //           // t1.width = t1.width * opt.target.scaleX;

  //           let sX = target.scaleX;
  //           let sY = target.scaleY;
  //           target.width *= sX;
  //           target.height *= sY;
  //           target.scaleX = 1;
  //           target.scaleY = 1;
  //           opt.target._objects[1].width = target.width;
  //           opt.target._objects[0].width = target.width;
  //           vm.$forceUpdate();
  //         }
  //       } catch (error) {
  //         console.log(error, 'error');
  //       }
  //     });
  //     this.canvas.preview.on('object:scaling', function(opt) {
  //       let target = opt.target;
  //       if (!target || target.type !== 'group') {
  //         return;
  //       }
  //       let sX = target.scaleX;
  //       let sY = target.scaleY;
  //       target.width *= sX;
  //       target.height *= sY;
  //       target.scaleX = 1;
  //       target.scaleY = 1;
  //       opt.target._objects[1].width = target.width;
  //       opt.target._objects[0].width = target.width;
  //     });
  //   },
  methods: {
    async getFontPersonalize() {
      try {
        this.fonts = (await personalizesApi.getFont()).data.map(item => {
          return {
            ...item,
            name: item.name === 'Lobster 1.3' ? 'Lobster' : item.name,
          };
        });
        for (let index = 0; index < this.fonts.length; index++) {
          const element = this.fonts[index];
          $('head').append(
            `<style type='text/css'>@font-face { font-family: '${element.name}' ; src: url(${element.fontUrl}); }</style>`,
          );
          let text = new fabric.Textbox('A', {
            fontFamily: element.name,
          });
          this.loadFCV.add(text);
          this.loadFCV.renderAll();
        }
      } catch (error) {
        console.log(error, 'error');
      }
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    stepSetupProduct() {
      // to step 2
      this.e1 = 2;
      this.formData.productionSvg = this.canvas.product.toSVG();
      console.log('this.canvas.product', this.canvas.product);
      this.formData.productionJson = this.canvas.product.toDatalessJSON();
      console.log(this.formData);
    },
    stepSetupPreview() {
      this.e1 = 3;
      this.formData.previewSvg = this.canvas.preview.toSVG();
      this.formData.previewJson = this.canvas.preview.toDatalessJSON();
      let vm = this;
      let parser = new DOMParser();
      let xmlDocProduct = this.formData.productionSvg
        ? parser.parseFromString(this.formData.productionSvg, 'text/xml')
        : null;
      let xmlDocPreview = this.formData.previewSvg
        ? parser.parseFromString(this.formData.previewSvg, 'text/xml')
        : null;

      if (xmlDocProduct && xmlDocProduct.querySelector('image')) {
        xmlDocProduct.querySelector('svg').setAttribute('width', '100%');
        xmlDocProduct.querySelector('svg').setAttribute('height', '100%');
        let attributes = xmlDocProduct.querySelector('image').attributes;
        document.querySelector('.personalize-production').style.width = parseFloat(attributes.width.value) + `px`;
        document.querySelector('.personalize-production').innerHTML = xmlDocProduct.querySelector('svg').outerHTML;
      }
      if (xmlDocPreview && xmlDocPreview.querySelector('image')) {
        xmlDocPreview.querySelector('svg').setAttribute('width', '100%');
        xmlDocPreview.querySelector('svg').setAttribute('height', '100%');
        let attributes = xmlDocPreview.querySelector('image').attributes;
        document.querySelector('.personalize-preview').style.width = parseFloat(attributes.width.value) + `px`;
        document.querySelector('.personalize-preview').innerHTML = xmlDocPreview.querySelector('svg').outerHTML;
      }
    },
    removeStringXML(string) {
      let newSvg;
      let indexTagSVg = string.search('<svg xmlns');
      newSvg = string.substring(indexTagSVg);
      return newSvg;
    },
    checkFonts() {
      let type = ['product', 'preview'];
      let fontsCanvas = [];
      for (let i = 0; i < type.length; i++) {
        let obj =
          this.canvas[type[i]] && this.canvas[type[i]].toDatalessJSON() ? this.canvas[type[i]].toDatalessJSON() : null;
        if (obj) {
          for (let o = 0; o < obj.objects.length; o++) {
            fontsCanvas.push(obj.objects[o].objects[1].fontFamily);
          }
        }
      }
      return this.fonts.filter(item => fontsCanvas.includes(item.name));
    },
    // getAttributes(objects){

    // },
    async submit() {
      try {
        this.isLoading = true;
        let res = await personalizesApi.create({
          productId: this.$route.params.id,
          previewSvg: this.removeStringXML(this.formData.previewSvg),
          productionSvg: this.removeStringXML(this.formData.productionSvg),
          createdById: this.$store.state.auth.user.id,
          fonts: this.checkFonts(),
          //one 1 group [text,rect]
          productionJson: JSON.stringify(this.formData.productionJson),
          previewJson: JSON.stringify(this.formData.previewJson),
          countTexts: this.formData.previewJson.objects.length,
          productAttributes: this.formData.productionJson.objects,
          previewAttributes: this.formData.previewJson.objects,
        });
        this.$router.push({ name: 'website.product-personalized' });
      } catch (error) {
        console.log(error, 'error');
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
      this.isLoading = false;
    },

    changeFsProductSetting(options, type) {
      let vm = this;
      try {
        let objText =
          this.canvas[type] && this.canvas[type].getActiveObject() && this.canvas[type].getActiveObject()._objects
            ? this.canvas[type].getActiveObject()._objects[1]
            : null;
        let objRect =
          this.canvas[type] && this.canvas[type].getActiveObject() && this.canvas[type].getActiveObject()._objects
            ? this.canvas[type].getActiveObject()._objects[0]
            : null;
        if (objText && objRect && !this.isAdd) {
          console.log('objText', objText);
          objText.set('fontFamily', options.fontFamily);
          console.log('options.fontSize', options.fontSize);
          objText.set('fontSize', parseInt(options.fontSize.substring(0, options.fontSize.length - 2)));
          objText.set('fontWeight', options.bold ? 'bold' : ''); //normal  //bold
          objText.set('underline', options.underline);
          // objText.set('textTransform', options.caps ? 'uppercase' : '');
          objText.set('textAlign', options.align);
          objText.set('fontStyle', options.italic ? 'italic' : '');
          objText.set('effect', options.effect);
          objText.set('fill', options.fontColor);
          objText.set('styles', options.caps ? 'uppercase' : '');
          objText.set(
            'text',
            !options.caps ? options.text.replace(/\s/g, '') : options.text.replace(/\s/g, '').toUpperCase(),
          );
          objText.set('textLines', [options.text]);
          objText.set('maxLength', options.maxLength);
          this.canvas[type].getActiveObject()._objects[0].width = this.canvas[type].getActiveObject()._objects[1].width;
          this.canvas[type].getActiveObject().width = this.canvas[type].getActiveObject()._objects[1].width;
          this.canvas[type].renderAll();
          this.isChangeOption = false;
        }
        this.isAdd = false;
      } catch (error) {
        console.log(error, 'error');
      }
    },
    addText(type) {
      this.isAdd = true;
      let rect = new fabric.Rect({
        fill: '#dddddd',
        opacity: 0,
        // width: 100,
      });
      let text = new fabric.Textbox('A', {
        width: 100,
        // top: 5,
        // left: 5,
        fontSize: 25,

        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        fontFamily: 'Arial',
        id: `per_text_${this.canvas[type].getObjects().length}`,
      });

      let group = new fabric.Group([rect, text], {
        width: 100,
        // originX: 'center',
        // originY: 'center',
        // textAlign: 'center',
        padding: 10,
        // lockUniScaling: true,
        // lockMovementX: true,
      });
      this.canvas[type].discardActiveObject();
      this.canvas[type].add(group);
      this.canvas[type].centerObject(group);
      group.setCoords();
      group.setControlsVisibility({
        // mt: false,
        // mb: false,
        // ml: false,
        // mr: false,
        // bl: false,
        // br: false,
        // tl: false,
        // tr: false,
        // mtr: false,
      });
      this.canvas[type].renderAll();
      this.optionsProductSetting = { ...this.defaultSetting };
      this.optionsPreviewSetting = { ...this.defaultSetting };
      this.$forceUpdate();
    },
    getDataStyle(data, type) {
      this.changeFsProductSetting(data, type);
    },
    uploadImagePreview(e) {
      this.handleUploadImage(e, 'preview');
    },
    checkAspect(size) {
      if (size.width < this.maxWidth) {
        return 1;
      }
      if (size.width > 2 * this.maxWidth) {
        return 0.25;
      } else return 0.5;
    },
    async handleUploadImage(e, type) {
      var file = e.target.files[0];
      if (file) {
        var img = new Image();
        let vm = this;
        let size = { width: 600, height: 600 };
        this.overlay = true;

        img.onload = async function() {
          console.log('121');
          size = { width: img.width, height: img.height };
          let per = vm.checkAspect(size);
          var f_img = new fabric.Image(img);
          vm.myEventHandler(size, type, per);
          vm.canvas[type].setBackgroundImage(f_img, vm.canvas[type].renderAll.bind(vm.canvas[type]), {
            scaleX: per,
            scaleY: per,
          });
          vm.canvas[type].renderAll();
          vm.overlay = false;
          vm.resetImageUploader();
          console.log('add');
        };

        let uploadImage = await this.onChangeImage(e);
        if (uploadImage) {
          img.src = '';
          this.imageUrl[type] = this.imageHelpers.url(uploadImage.src, size);
          img.src = this.imageUrl[type];
        } else {
          this.overlay = false;
        }
      }
    },
    async uploadImageProduct(e, type) {
      this.handleUploadImage(e, 'product');
    },
    myEventHandler(size, type, per) {
      let percent = per ? per : 0.5;
      let w = size ? `${size.width * percent}px` : `500px`;
      let h = size ? `${size.height * percent}px` : `500px`;
      this.canvas[type].setDimensions({ width: size.width * percent, height: size.height * percent });
      this.previewSetting[type].setDimensions({ width: size.width * percent, height: size.height * percent });
      if (type === 'product') {
        $('#cp')
          .css('width', w)
          .css('height', h);
        $('#cpp')
          .css('width', w)
          .css('height', h);
      } else {
        $('#cpr')
          .css('width', w)
          .css('height', h);
        $('#cprp')
          .css('width', w)
          .css('height', h);
      }

      $(`.${type}-canvas .canvas-container `)
        .css('width', w)
        .css('height', h);
      $(`.${type}-canvas .upper-canvas`)
        .css('width', w)
        .css('height', h);
    },
    refresh() {
      location.reload();
    },
    deleteSelection(type) {
      var activeGroup = this.canvas[type].getActiveObject();
      this.canvas[type].remove(activeGroup);
      this.optionsProductSetting = this.defaultSetting;
      this.optionsPreviewSetting = this.defaultSetting;
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      console.log('e', e.target.files[0]);

      let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (inputTypes.includes(e.target.files[0].type)) {
        let file = e.target.files[0];
        try {
          let type = file.name.split('.')[file.name.split('.').length - 1];
          let id = this.objectId();
          let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
          if (urlData) {
            let res = await axios.put(urlData.data.presignedUrl, file, {
              headers: {
                'Content-Type': `${file.type}`,
              },
            });
            let obj = {
              alt: file.name,
              alt_text: file.name,
              src: urlData.data.imagePath,
              _id: id,
            };
            return obj;
          }
        } catch (error) {
          this.$store.commit('setMessages', {
            messages: 'Error image ',
            type: 'error',
          });
        }
      } else {
        return false;
      }
    },
  },
};
